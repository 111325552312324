<template>
   <div ref="banner" id="banner">
      <div class="flex items-center gap-3 lg:gap-5">
         <p class="text-xs lg:text-lg font-bold">
            Registrati oggi <br />con un'offerta di
         </p>
         <h1 class="font-black text-3xl lg:text-6xl">€10</h1>
      </div>

      <button class="banner-register-btn">Registrati</button>
      <!-- <div class="hidden md:flex md:items-end">
         <img src="/img/banners/casinomania-1.png" class="h-10 mt-16 lg:mt-28 lg:h-16" />
      </div> -->
   </div>
</template>
<script>
export default {
   name: "FullWidthBanner",
};
</script>
<style lang="postcss" scoped>
#banner {
   /* -webkit-background-size: contain;
   -moz-background-size: contain;
   -o-background-size: contain;
   background-size: contain; */
   /* background-repeat: no-repeat; */
   /* background-attachment: fixed; */
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
   background-size: cover;
   background-position: center center;
   height: 25vh;
   @apply w-full px-4 py-2 flex flex-col justify-center;

   @screen md {
      @apply px-10;
   }

   @screen lg {
      @apply h-136 px-20 py-16;
   }
}

.banner-register-btn {
   width: fit-content;
   @apply px-6 lg:px-16 py-0.5 lg:py-2 text-xs lg:text-lg uppercase rounded-full mt-1 lg:mt-6 cursor-pointer border-2 border-transparent bg-primary text-gray-900 hover:bg-secondary;
}

.no-js #banner {
   background-image: url("/img/banners/BANNER_v1.png");
}

.webp #banner {
   background-image: url("/img/banners/BANNER_v1.webp");
}
</style>
