<template>
   <section class="w-full flex flex-col">
      <SectionTitle :title="title">
         <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
            ><slot :name="slot" v-bind="scope"
         /></template>
      </SectionTitle>

      <slot name="special-title"></slot>

      <button
         v-if="showMainButton"
         class="self-end uppercase bg-primary hover:bg-secondary text-black rounded-full px-12 py-2 text-sm font-semibold mr-16 mt-6"
      >
         Vedere tutto
      </button>

      <!-- <div
         class="my-16 relative w-full flex items-center justify-between"
         v-if="false"
      >
         <button
            class="h-full focus:outline-none text-white hover:text-primary"
            v-if="sideNavigation"
         >
            <svg
               class="w-16 h-16 stroke-current"
               fill="none"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M15 19l-7-7 7-7"
               />
            </svg>
         </button>

         <div class="flex-1 grid gap-5" :class="gridColsClass">
            <a
               v-for="(game, index) in games"
               :key="index"
               :href="game.gameUrl"
               class="relative cursor-pointer group"
            >
               <custom-image class-list="mx-auto" :original="game.image" />

               <div
                  class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                  style="bottom: 40%"
               >
                  <button
                     class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1"
                  >
                     Gioca Ora
                  </button>
               </div>
            </a>
         </div>

         <button class="h-full text-primary" v-if="sideNavigation">
            <svg
               class="w-16 h-16"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M9 5l7 7-7 7"
               />
            </svg>
         </button>
      </div> -->

      <!-- Slider main container -->
      <div class="swiper relative w-full">
         <!-- Additional required wrapper -->
         <div class="swiper-wrapper">
            <!-- Slides -->
            <a
               v-for="(game, index) in games"
               :key="index"
               :href="game.gameUrl"
               class="relative cursor-pointer group swiper-slide"
            >
               <custom-image class-list="mx-auto" :original="game.image" />

               <div
                  class="absolute w-full text-center opacity-0 group-hover:opacity-100 transition duration-300"
                  style="bottom: 40%"
                  v-if="!supportsTouch"
               >
                  <button
                     class="uppercase bg-primary text-black text-md font-semibold rounded-full px-8 py-1"
                  >
                     Gioca Ora
                  </button>
               </div>
            </a>
         </div>

         <!-- If we need navigation buttons -->
         <div class="swiper-button-prev"></div>
         <div class="swiper-button-next"></div>
      </div>

      <div
         v-if="bottomNavigation"
         class="w-full flex items-center justify-center gap-5"
      >
         <button
            class="h-full focus:outline-none text-white hover:text-primary"
         >
            <svg
               class="w-8 h-8 stroke-current"
               fill="none"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M15 19l-7-7 7-7"
               />
            </svg>
         </button>

         <p class="text-sm"><span>1</span> di <span>4</span></p>

         <button class="h-full text-primary">
            <svg
               class="w-8 h-8"
               fill="none"
               stroke="currentColor"
               viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg"
            >
               <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="{2}"
                  d="M9 5l7 7-7 7"
               />
            </svg>
         </button>
      </div>
   </section>
</template>

<script>
import SectionTitle from "@/components/SectionTitle";
import Swiper, { Navigation, Pagination } from "swiper";

export default {
   components: { SectionTitle },
   props: {
      title: String,
      url: String,
      games: Array,
      showMainButton: {
         required: false,
         default: false,
         type: Boolean,
      },
      numberColumns: {
         required: false,
         default: 5,
         type: Number,
      },
      sideNavigation: {
         required: false,
         default: true,
         type: Boolean,
      },
      bottomNavigation: {
         required: false,
         default: false,
         type: Boolean,
      },
   },

   data() {
      return {
         minWidth1024: window.innerWidth >= 1024,
         swiper: null,
         supportsTouch: navigator.maxTouchPoints > 0
      };
   },

   computed: {
      gridColsClass() {
         return `grid-cols-${this.numberColumns}`;
      },
   },

   mounted() {
      window.addEventListener("resize", this.updateMinWidth1024);
      this.swiper = new Swiper(".swiper", {
         modules: [Navigation, Pagination],
         direction: "horizontal",
         centeredSlides: true,
         loop: true,
         breakpoints: {
            768: {
               slidesPerView: 2,
            },
            // 768: {
            //    slidesPerView: 3,
            // },
            1024: {
               slidesPerView: 4,
            },
            1280: {
               slidesPerView: 5,
            }

         },

         navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
         },
      });
   },

   methods: {
      updateMinWidth1024() {
         this.minWidth1024 = window.innerWidth >= 1024;
         console.log(this.swiper)
         // for (let swipe of this.swiper) {
         //    console.log(swipe)
         //    swipe.update();
         // }
         // this.swiper.update();
      },
   },
};
</script>

<style lang="postcss">
.swiper-button-next,
.swiper-button-prev {
   color: white;
}
</style>
